/** @jsx jsx */
import React, { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import { jsx, Flex, Box, Grid, Container, Styled } from 'theme-ui';
import { Hero, Layout } from '../components';
import { ContentfulWidgets } from '../components/widgets';
import RichText from '../components/RichText';
import { useDispatch, useSelector } from 'react-redux';
import { changeLocale } from '../state/session';
import Helmet from 'react-helmet';
import { getLinkForPage } from '../utils/links';
import PageNavigation from '../components/PageNavigation';
import PageHero from '../components/PageHero';
import PageTitleAndExcerpt from '../components/PageTitleAndExcerpt';
import Breadcrumb from '../components/Breadcrumb';
import * as analytics from '../utils/analytics';
import { BrowserWrapper, isBrowser } from '../utils';

const Page = ({ data, pageContext, location }) => {
  const hideNav = isBrowser && new URLSearchParams(location?.search).get('hideNav') === 'true';
  const {
    contentful_id,
    title,
    body,
    image,
    wideImage,
    parentPage,
    node_locale,
    description,
    keywords,
    updatedAt,
    excerpt,
    widgets,
    category,
    widePage,
    inheritParentWidgets,
  } = data.contentfulPage;
  const locale = useSelector(state => state.session.locale);
  const dispatch = useDispatch();
  useEffect(() => {
    const pageLocale = node_locale && node_locale.includes('-') && node_locale.substring(0, node_locale.indexOf('-'));
    if (pageLocale && pageLocale !== locale) {
      dispatch(changeLocale(pageLocale));
    }
  }, []);
  analytics.usePageCategory(category || parentPage || 'not_set');

  let breadcrumb = [];
  let theme = null;
  let navigation = null;
  let hideNormalNavigation = false;
  let currentParentPage = parentPage;
  let parentSlug = '/';
  while (currentParentPage) {
    parentSlug = '/' + currentParentPage.slug;
    if (currentParentPage.theme) {
      theme = currentParentPage.theme;
    }
    if (currentParentPage.navigation) {
      navigation = currentParentPage.navigation;
    }
    if (currentParentPage.hideNormalNavigation) {
      hideNormalNavigation = currentParentPage.hideNormalNavigation;
    }
    if (!hideNormalNavigation) {
      breadcrumb.unshift({
        title: currentParentPage.title,
        to: getLinkForPage(currentParentPage),
      });
    }
    currentParentPage = currentParentPage.parentPage;
  }

  let pageWidgets = null;
  const inheritWidgetsFromParent = inheritParentWidgets === null || inheritParentWidgets;
  if (!widgets && parentPage && parentPage.widgets && inheritWidgetsFromParent) {
    pageWidgets = parentPage.widgets;
  } else {
    pageWidgets = widgets;
  }

  // content should be 2/3 of width if there are widgets, or if widePage is false (= default)
  const isNarrowPage = pageWidgets || !widePage;

  const pageAvailableInLocale = useMemo(() => {
    return !(pageContext.hideLocales || []).includes(locale);
  }, [pageContext, locale]);

  if (hideNav) {
    return (
      <BrowserWrapper>
        <Styled.root>
          <Box sx={{ p: 3 }}>
            <PageHero
              title={title}
              excerpt={excerpt}
              image={image}
              theme={theme}
              layout={!pageWidgets ? 'text' : 'container'}
            />
            <Box variant="layout.text" sx={{ width: '100%', 'h2:first-child': { mt: 0 } }} lang={locale || 'fi'}>
              {body && (
                <RichText
                  content={body}
                  locale={locale}
                  parentId={contentful_id}
                  assets={pageContext.assetsInBody}
                  layout={isNarrowPage ? 'narrow' : null}
                />
              )}
            </Box>
          </Box>
        </Styled.root>
      </BrowserWrapper>
    );
  }

  return (
    <Layout
      title={title}
      showAppBanner={true}
      path={pageContext.paht}
      paths={pageContext.paths}
      description={description}
      keywords={keywords}
      locale={node_locale}
      updatedAt={updatedAt}
      image={image ? image.fluid.src : null}
      showNavigation={navigation ? navigation.location !== 'belowHero' : true}
      navigation={navigation}
      hideNormalNavigation={hideNormalNavigation}
      parentSlug={parentSlug}
      theme={theme}
      hideLocales={pageContext?.hideLocales || []}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            headline: title,
            image: {
              '@type': 'ImageObject',
              url: image ? image.fluid.src : null,
            },
            name: title,
            description: description,
          })}
        </script>
      </Helmet>

      {!hideNormalNavigation && (
        <Container
          variant="wide"
          sx={{
            position: 'relative',
            display: image ? ['none', null, 'block'] : 'block',
          }}
        >
          <Breadcrumb
            links={breadcrumb}
            color={['dark', 'green', 'mh.se', 'blue'].includes(theme) ? 'white' : 'gray'}
            sx={!!theme ? { position: 'absolute', mt: [2, 3] } : { mt: 2 }}
          />
        </Container>
      )}

      {wideImage && !!image ? (
        <Hero style="wide" backgroundImage={image} theme={theme} />
      ) : (
        <PageHero title={title} excerpt={excerpt} image={image} theme={theme} />
      )}

      {!!navigation && navigation.location === 'belowHero' && (
        <PageNavigation navigation={navigation} theme={theme} hideWhenNarrow={hideNormalNavigation} />
      )}

      <Container sx={{ px: [3, null, null, null], py: [3, null, 4, 5] }}>
        <Flex
          sx={{
            justifyContent: 'center',
            flexWrap: ['wrap', null, null, 'nowrap'],
            gap: '56px',
          }}
        >
          <Box sx={{ width: '100%', 'h2:first-child': { mt: 0 }, wordWrap: 'break-word' }} lang={locale || 'fi'}>
            {wideImage && !!image ? <PageTitleAndExcerpt title={title} excerpt={excerpt} /> : null}

            {body && (
              <RichText
                content={body}
                locale={locale}
                parentId={contentful_id}
                assets={pageContext.assetsInBody}
                layout={isNarrowPage ? 'narrow' : null}
              />
            )}
          </Box>
          {isNarrowPage ? (
            <Grid
              sx={{
                alignSelf: 'flex-start',
                width: ['100vw', null, null, '41%'],
              }}
              gap={[2, 3]}
            >
              <ContentfulWidgets widgets={pageWidgets} sidebar />
            </Grid>
          ) : null}
        </Flex>
      </Container>
    </Layout>
  );
};
export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      contentful_id
      title
      slug
      hideLocales
      excerpt {
        excerpt
        childMarkdownRemark {
          html
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            ...assetFields
          }
          ... on ContentfulPage {
            ...pageLink
          }
          ... on ContentfulBlogPost {
            ...blogLink
          }
          ... on ContentfulAccordion {
            ...accordionFields
          }
          ... on ContentfulComponent {
            ...componentFields
          }
          ... on ContentfulContact {
            ...contactFields
          }
          ... on ContentfulFrequentlyAskedQuestion {
            ...faqFields
          }
          ... on ContentfulFrequentlyAskedQuestionCategory {
            ...faqCategoryFields
          }
          ... on ContentfulLayoutLink {
            ...layoutLinkFields
          }
          ... on ContentfulLayoutWidgetList {
            ...widgetListFields
          }
          ... on ContentfulPageTable {
            ...tableFields
          }
          ... on ContentfulPageSection {
            ...pageSectionFields
          }
          ... on ContentfulLayoutHighlight {
            ...highlightFields
          }
          ... on ContentfulServicePointMap {
            ...servicePointMapFields
          }
          ... on ContentfulCarousel {
            ...carouselFields
          }
        }
      }
      widePage
      inheritParentWidgets
      image {
        fluid(maxWidth: 1440, quality: 60) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      wideImage
      parentPage {
        ... on ContentfulLayout {
          id
          slug
          title
          theme
          navigation {
            ...navigationFields
          }
          hideNormalNavigation
          widgets {
            contentful_id
            type
            title
            description {
              description
            }
            ctaTitle
            ctaLink
            visualStyle
          }
        }
        ... on ContentfulPage {
          id
          slug
          title
          parentPage {
            ... on ContentfulLayout {
              id
              slug
              title
              theme
              navigation {
                ...navigationFields
              }
              hideNormalNavigation
            }
          }
        }
      }
      node_locale
      description
      keywords
      updatedAt
      widgets {
        contentful_id
        type
        title
        description {
          description
        }
        ctaTitle
        ctaLink
        visualStyle
        image {
          ...assetFields
        }
      }
      category
    }
  }
`;
